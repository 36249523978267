import React from "react"

const olymptradeLink = [
  "รวว-olymp-trade-–-โบรกเกอรหลอกลวงหรอของจรง",
]

const iqoptionLink = [
  "รวว-iq-option-–-โบรกเกอรหลอกลวงหรอของจรง",
]

class Sidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      link: undefined,
    }
  }
  componentDidMount() {
    const link = window.location.pathname.replace("/", "");
    this.setState({ link });
  }
  render() {
    return (
      <>
        <div className="col-lg-4 col-md-12">
        <div className="sidebar sticky-top">

<div className="sidebar-rev">

  {(this.state.link && olymptradeLink.some(x => x === decodeURI(this.state.link))) ?
    <>
      <div className="panel panel-default">
        <div className="panel-heading">
        ลงทะเบียน Olymp Trade รับฟรี $ 10,000 ไปยังบัญชี DEMO
        </div>
        <div className="panel-body">
          {<iframe src="https://olymptrade.com/lands/affiliate-new-form/index.html?lang=th&dark=false&square=false&horizontal=false&subid1=fxsinergi-form&subid2=&affiliate_id=1060014&no_redirect=1&freereg=false&auth=false" width="100%" height="320" frameborder="0"></iframe>}
        </div>
      </div>
    </>
    : null
  }
</div>
<div className="sidebar-rev">

  {(this.state.link && iqoptionLink.some(x => x === decodeURI(this.state.link))) ?
    <>
      <div className="panel panel-default">
        <div className="panel-heading">
        ลงทะเบียน IQ Option รับฟรี $ 10,000 ไปยังบัญชี DEMO
        </div>
        <div className="panel-body">
          {<iframe src="https://iqbroker.com/lp/regframe-01-dark/th/?aff=168082&afftrack=fxsinergi-form" width="100%" height="730" frameborder="0"></iframe>}
        </div>
      </div>
    </>
    : null
  }
</div>
<>
{/* <div style={{ textAlign: 'center' }}>
              <a href="https://one.exnesstrack.com/intl/th/a/c_0buwzjm5sb">
              <img src="https://d3dpet1g0ty5ed.cloudfront.net/TH_0222_lower_your_trading_costs_with_Ex_320x480px.jpg" width="320" height="480" alt="" />
              </a>
</div> */}

</>
            <div className="sidebar-rev" style={{ marginTop: 10 }}>
              <div className="panel panel-default">
                <div className="panel-heading">
                โบรกเกอร์ Forex ที่ดีสุด
                </div>
                <div className="panel-body">
                  <div className="row align-items-center text-md-left text-center">
                    <div className="col-md-4">
                      <a href="https://link.fxsinergi.com/iqoption-all-traffic" rel="nofollow" title="Exness">
                        <img alt="exness thailand" src="https://dmf5.xyz/Media/images/20201104/49fa8dd3-e57a-48a8-8818-cdb561b47747.png" />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="stt">
                      ฟีเจอร์ Social trading
                      </div>
                      <div className="stt-su">
                      เงินฝากเริ่มต้น $10
                      </div>
                      <div className="btn-p2">
                        <a href="/รววโบรกเกอรฟอเรกซ-exness:-ถกกฎหมาย-หรอ-หลอกลวง" className="btn-1">รีวิว</a>
                        <a href="https://link.fxsinergi.com/exness" rel="nofollow" className="btn-2">เทรดเลยตอนนี้!</a>
                      </div>
                    </div>
                  </div>  

                  <div className="row align-items-center text-md-left text-center">
                    <div className="col-md-4">
                      <a href="https://link.fxsinergi.com/olymptrade-all-traffic" rel="nofollow" title="xm thailand">
                        <img alt="olymptrade thai" src="https://dmf5.xyz/Media/images/20201104/2873ed0c-a485-458f-83ad-9a2e6487cb2a.png" />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="stt">
                      เริ่มต้นด้วยโบนัสซื้อขาย $30
                      </div>
                      <div className="stt-su">
                      เงินฝากเริ่มต้น $5
                      </div>
                      <div className="btn-p2">
                        <a href="/xm-broker-รววโบรกเกอรทดทสดในการเทรด-forex" className="btn-1">รีวิว</a>
                        <a href="https://link.fxsinergi.com/broker-xm" rel="nofollow" className="btn-2">เทรดเลยตอนนี้!</a>
                      </div>
                    </div>
                  </div>  

                  <div className="row align-items-center text-md-left text-center">
                    <div className="col-md-4">
                      <a href="https://link.fxsinergi.com/expertoption" rel="nofollow" title="Pepperstone">
                        <img alt="expert option thailand" src="https://dmf5.xyz/Media/images/20201104/31c268ec-b361-4f4f-b899-f9fb9e163e24.jpeg" />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="stt">
                      บัญชีทดลองใช้ฟรี
                      </div>
                      <div className="stt-su">
                      เงินฝากเริ่มต้น $200
                      </div>
                      <div className="btn-p2">
                        <a href="/รววโบรกเกอรฟอเรกซ-pepperstone:-เปนโบรกเกอรฟอเรกซทดหรอไม" className="btn-1">รีวิว</a>
                        <a href="/" rel="nofollow" className="btn-2">เทรดเลยตอนนี้!</a>
                      </div>
                    </div>
                  </div>  
                  
                </div>
              </div>

            </div>
            <div style={{ textAlign: 'center' }}>
              <a href="https://quotex.io/sign-up/fast/?lid=70867">
              <img src="https://static.quotex.io/files/10_en/300_250.jpg"></img>
              </a>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Sidebar